import type {
  AppManifest,
  ComponentRef,
  ContextParams,
} from '@wix/platform-editor-sdk';
import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { modifyAppManifest } from './editor-app/manifest/modifyAppManifest';
import { onEditorReady } from './editor-app/editorReady';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  return onEditorReady(editorSDK, appDefId, options, flowAPI);
};

export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  return modifyAppManifest(options, editorSDK, flowAPI);
};

export const exports = (editorSDK: FlowEditorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      return (
        await editorSDK.application.appStudioWidgets.getPreset('', {
          componentRef: widgetRef,
        })
      ).style;
    },
  },
  public: {
    onPresetsChange: async ({
      variationId,
      componentRef,
    }: {
      variationId: string;
      componentRef: ComponentRef;
    }) => {
      const [parentRefComponent] = await editorSDK.components.getAncestors('', {
        componentRef,
      });
      await editorSDK.application.appStudioWidgets.changePreset('', {
        componentRef: parentRefComponent,
        stylePresetId: variationId,
        layoutPresetId: variationId,
      });
    },
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      return (
        await editorSDK.application.appStudioWidgets.getPreset('', {
          componentRef: widgetRef,
        })
      ).style;
    },
  },
});
