import { WidgetPreset } from '@wix/platform-editor-sdk';

export const ExpandModPolyWidgetPresetsArray: WidgetPreset[] = [
  {
    // 1.2
    id: 'externalPreset-#8c2ad7ce-6161-4ab6-afbf-d4aec9aef6da#variants-m3cwi2fk1',
    src: 'https://img.freepik.com/free-photo/hand-holding-number-1_53876-63820.jpg',
  },
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_bbf76682aa4f47f1aa69343f0cf5616e~mv2.png',
  //   id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7g',
  // },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_f78357746f094cb0a340936dcb5c7d3e~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7g1',
  },
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_bf7b3b9721f94ffc965a6f62b136eb75~mv2.png',
  //   id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7g2',
  // },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_164ee3b2039b46ec88f9d9c903a684cc~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7g3',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_88752b2fefa840e086a3c24f86d08550~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7h',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_0d1b81d260bd45d8b926953297544774~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7h2',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_8467e1018b434011b831357dd40646e4~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7h1',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_310618497b144c12913c90881a325d7b~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7h3',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_334cda4fb9a842538079ee863b88aaf4~mv2.png',
    id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7h4',
  },
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_de6f9ac560c7482fa9d857a53f001998~mv2.png',
  //   id: 'externalPreset-#2a65c4c4-b099-4310-a6ff-36ae3649d92d#variants-m1eyuv7h5',
  // },
];
