import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

const presets = [
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_bbf76682aa4f47f1aa69343f0cf5616e~mv2.png',
  //   id: 'variants-m1eyuv7g',
  // },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_f78357746f094cb0a340936dcb5c7d3e~mv2.png',
    id: 'variants-m1eyuv7g1',
  },
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_bf7b3b9721f94ffc965a6f62b136eb75~mv2.png',
  //   id: 'variants-m1eyuv7g2',
  // },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_164ee3b2039b46ec88f9d9c903a684cc~mv2.png',
    id: 'variants-m1eyuv7g3',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_88752b2fefa840e086a3c24f86d08550~mv2.png',
    id: 'variants-m1eyuv7h',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_0d1b81d260bd45d8b926953297544774~mv2.png',
    id: 'variants-m1eyuv7h2',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_8467e1018b434011b831357dd40646e4~mv2.png',
    id: 'variants-m1eyuv7h1',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_310618497b144c12913c90881a325d7b~mv2.png',
    id: 'variants-m1eyuv7h3',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_334cda4fb9a842538079ee863b88aaf4~mv2.png',
    id: 'variants-m1eyuv7h4',
  },
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_de6f9ac560c7482fa9d857a53f001998~mv2.png',
  //   id: 'variants-m1eyuv7h5',
  // },
];
const setDesktopGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  widgetBuilder.gfpp().set('add', {
    behavior: 'HIDE',
  });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  widgetBuilder.set({
    displayName: 'Widget ExpandModeMainWidget',
    nickname: 'ExpandModeMainWidgetWidget',
  });
  setDesktopGFPP(widgetBuilder, editorSDK, flowAPI);

  widgetBuilder.behavior().set({
    resizable: false,
  });
  widgetBuilder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });

  // widgetBuilder.configureWidgetPresets((widgetPresetsBuilder) => {
  //   widgetPresetsBuilder.setPresets(presets);
  // });
};
